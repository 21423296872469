import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import SignIn from '../components/Signin'

const SignInPage = () => {
    return (
        <>
        <ScrollToTop />
           <SignIn />
        </>
    )
}

export default SignInPage
